import React from 'react';
import Sidebar from '../Components/Sidebar';
 
import PageHead from '../Components/PageHead';
import Settingspage from '../Components/Settings';

const Settingpage = () => {
  return (
    <div>
      <PageHead/>
      <div style={{ display: 'flex' }}>
        <Sidebar />
        <Settingspage/>
      </div>
    </div>
  );
};

export default Settingpage;
