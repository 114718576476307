import axios from 'axios';
import {environment} from './environment';
import {toast} from 'react-toastify';



const axiosClient = axios.create({
  baseURL: environment.baseUrl,
});

axiosClient.interceptors.request.use(config => {
  const authToken = localStorage.getItem('authToken');
  if (authToken) config.headers.Authorization = authToken;
  return config;
});

axiosClient.interceptors.response.use(
  response => response,
  err => {
    if (err.response.status === 403) {
      toast.info('Session expired, Please login again to continue');
      window.location.href = '/'
    } else {
      toast.error(err.response.data.message)
    }
    return Promise.reject(err);

  } 
);

export {axiosClient};
