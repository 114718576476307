import React, {useEffect, useState} from 'react';
import {PageHead} from './PageHead';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {Link} from 'react-router-dom';
import {axiosClient} from '../config/axios.config';
import {toast} from 'react-toastify';
import { fetchItem } from '../utils/fetchItem';
import { environment } from '../config/environment';
import editBtn from '../edit.png'
import deleteBtn from '../delete.png'
import { deleteItem } from '../utils/deleteItem';

function ProductsPage() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [categoryName, setCategoryName] = useState('');
  const [categoryImage, SetCategoryImage] = useState(null);

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  const [subCategoryName, setSubCategoryName] = useState('');
  const [subCategoryImage, SetSubCategoryImage] = useState(null);
  const [parentCategoryId, setParentCategoryId] = useState('');
  const [subcategoryDescription, setSubCategoryDescription] = useState('')

  const [productCategory, setProductCategory] = useState('');
  const [productSubCategory, setProductSubCategory] = useState('');
  const [productName, setProductName] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [productImage, setProductImage] = useState(null);

  const [toEditProduct, setToEditProduct] = useState({});
  const [showEditModal, setShowEditModal] = useState(false);
  const [showEditCateModal, setShowEditCateModal] = useState(false);
  const [showEditSubCatModal, setShowEditSubCatModal] = useState(false);

  const [products, setProducts] = useState([]);

  const [subCategoryList, setSubCategoryList] = useState([]);

  fetchItem('/products', setProducts);

  const [categoryDescription, setCategoryDescription] = useState('');

  const [editCategory, setEditCategory] = useState({});
  const [editSubCategory, setEditSubCategory] = useState({});

  function handleEditProductSubmission(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', toEditProduct?.name);
    formData.append('description', toEditProduct?.description);
    formData.append('category',toEditProduct?.category);
    formData.append('sub_category', toEditProduct?.sub_category);  
    formData.append('product_image', toEditProduct?.image);
    axiosClient.put('/products/' +toEditProduct?._id, formData).then((response) => {
      toast.success('Product edited succefully');
      // setProducts(products.map((el) => {
      //   if (el._id === toEditProduct?._id) return response.data.data;
      //   else return el;
      // }))
    })
  }


   async function handleShowEditModal(data) {
    data.image = '';
    data.category = data.category?._id;
    data.sub_category = data.sub_category?._id;
    setToEditProduct(data);
    await axiosClient.get('/subcategory/' + data.category).then((response) => {
      setSubCategories(response.data.data);
   })
    setShowEditModal(true);
  };

  function handleShowCategoryEditModal(data){
    data.image = '';
    setEditCategory(data);
    setShowEditCateModal(true);
  }

  function handleShowSubCatEditModal(data){
    data.image = '';
    data.parent_category = data?.parent_category?._id;
    setEditSubCategory(data);
    setShowEditSubCatModal(true);
  }

  async function handleEditCategorySubmission(e){
    e.preventDefault();
    const data = editCategory;
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('description', data.description);
    if(data.image){
      formData.append('category_image', data.image);
    }
    axiosClient.put('/category/' + data?._id, formData).then((response) => {
      toast.success('Category edited succefully');
      setCategories(categories.map((el) => {
        if (el._id === data._id) return response.data.data;
        else return el;
      }))
    })
  }

  async function handleEditSubCategorySubmission(e){
    e.preventDefault();
    const data = editSubCategory
    const formData = new FormData();
    formData.append('name', data?.name);
    formData.append('description', data?.description);
    formData.append('parent_category', data?.parent_category);
    if(data?.image){
      formData.append('subcategory_image', data?.image);
    };
    axiosClient.put('/subcategory/' + data?._id, formData).then((response) => {
      toast.success('Category edited succefully');
    })
  }

  async function onCategorySelected(e) {
    const value = e.target.value;
    setProductCategory(value);
    setToEditProduct((prev) => ({...prev, category: value}));
     await axiosClient.get('/subcategory/' + value).then((response) => {
        setSubCategories(response.data.data);
     })
  }


async function handleAddProductSubmission(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append('product_image', productImage);
    formData.append('name', productName);
    formData.append('description', productDescription);
    formData.append('category', productCategory);
    formData.append('sub_category', productSubCategory);
    axiosClient.post('/products', formData).then((response) => {
      toast.success('Product added successfully')
      setProducts([...products,response.data.data ])
    } ).catch(() =>{});
  }


  useEffect(() => {
    (async () => {
      axiosClient
        .get('/category')
        .then(response => setCategories(response.data.data))
        .catch(() => {});
    })();

    (async () => {
      axiosClient
        .get('/subcategory/list')
        .then(response => setSubCategoryList(response.data.data))
        .catch(() => {});
    })();
  }, []);

  async function handleAddSubCategorySubmission(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append('subcategory_image', subCategoryImage);
    formData.append('name', subCategoryName);
    formData.append('parent_category', parentCategoryId);
    formData.append('description', subcategoryDescription);
    await axiosClient
      .post('/subcategory', formData)
      .then(() => toast.success('Subcategory added successfully'))
      .catch(() => {});
  }

  async function handleAddCategorySubmission(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append('category_image', categoryImage);
    formData.append('name', categoryName);
    formData.append('description', categoryDescription);
    await axiosClient
      .post('/category', formData)
      .then(() => toast.success('Category added successfully'))
      .catch(() => {});
  }

  return (
    <div className='mainright'>
      <div className='productsdiv'>
        <Button variant='primary' onClick={handleShow}>
          {' '}
          Add Products Categories{' '}
        </Button>


        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className='formadmin'>
              <h2>Add Products Categories </h2>
              <div className='formdiv'>
                <form onSubmit={handleAddCategorySubmission}>
                  <div className='col-md-12'>
                    <label> Category Name </label>
                    <input
                      type='text'
                      name='categoryName'
                      placeholder='Enter Category'
                      required
                      onInput={e => setCategoryName(e.target.value)}
                    />
                  </div>

                  <div className='col-md-12'>
                    <label> Category Image </label>
                    <input
                      onChange={e => SetCategoryImage(e.target.files[0])}
                      type='file'
                      size='60'
                    ></input>
                  </div>

                  <div className='col-md-12'>
                    <label> Category Description </label>
                    <textarea
                      name='description'
                      placeholder='Enter Category Description'
                      required
                      onInput={e => setCategoryDescription(e.target.value)}
                    ></textarea>
                  </div>


                  <div className='col-md-12'>
                    <button className='submitbtn'> Submit </button>
                  </div>
                </form>
              </div>
            </div>

            <div className='formadmin'>
              <h2>Add subcategory </h2>
              <div className='formdiv'>
                <form onSubmit={handleAddSubCategorySubmission}>
                  <div className='col-md-12'>
                    <label> Subcategory name </label>
                    <input
                      type='text'
                      name='subCategoryName'
                      placeholder='Enter subcategory name'
                      required
                      onInput={e => setSubCategoryName(e.target.value)}
                    />
                  </div>

                  <div className='col-md-12'>
                    <label> Sub category Image </label>
                    <input
                      onChange={e => SetSubCategoryImage(e.target.files[0])}
                      type='file'
                      size='60'
                    ></input>
                  </div>

                  <div className='col-md-12'>
                    <label> Category Description </label>
                    <textarea
                      name='description'
                      placeholder='Enter Category Description'
                      required
                      onInput={e => setSubCategoryDescription(e.target.value)}
                    ></textarea>
                  </div>

                  <Form.Select onChange={e => setParentCategoryId(e.target.value)} aria-label='Default select example'>
                    <option>Select parent category Category</option>
                    {categories.map((el, index) => (
                      <option key={index} value={el._id}>
                        {el.name}
                      </option>
                    ))}
                  </Form.Select>

                  <div className='col-md-12'>
                    <button className='submitbtn' type='submit'> Submit </button>
                  </div>
                </form>
              </div>
            </div>


            <div className='formadmin'>
              <h2>Add Products </h2>
              <div className='formdiv'>
                <form onSubmit={handleAddProductSubmission}>
                  <Form.Select onChange={onCategorySelected} aria-label='Default select example'>
                    <option>Select Category</option>
                    {categories.map(el => (
                      <option key={el._id} value={el._id}>
                        {el.name}
                      </option>
                    ))}
                  </Form.Select>

                  <Form.Select style={{marginTop: '2rem'}} onChange={e => setProductSubCategory(e.target.value)} aria-label='Default select example'>
                    <option >Select Sub Category</option>
                    {subCategories.map(el => (
                      <option key={el._id} value={el._id}>
                        {el.name}
                      </option>
                    ))}
                  </Form.Select>

                  <div className='col-md-12'>
                    <label> Product name </label>
                    <input
                      type='text'
                      name='productName'
                      placeholder='Enter product name'
                      required
                      onInput={e => setProductName(e.target.value)}
                    />
                  </div>

                  <div className='col-md-12'>
                    <label> Product Description</label>
                    <textarea
                      id=''
                      name=''
                      rows='2'
                      cols=''
                      placeholder='type here Abouts Text'
                      onInput={e => setProductDescription(e.target.value)}
                    >
                    </textarea>
                  </div>

                  <div className='col-md-12'>
                    <label> Product Image </label>
                    <input onChange={e => setProductImage(e.target.files[0])} type='file' size='60'></input>
                  </div>

                  <div className='col-md-12'>
                    <button className='submitbtn'> Submit </button>
                  </div>
                </form>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>


        <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
      


            <div className='formadmin'>
              <h2>Edit Products </h2>
              <div className='formdiv'>
                <form onSubmit={handleEditProductSubmission}>
                  <Form.Select defaultValue={toEditProduct.category} onChange={onCategorySelected} aria-label='Default select example'>
                    <option>Select Category</option>
                    {categories.map(el => 
                       (
                        <option  key={el._id} value={el._id}>
                          {el.name}
                        </option>
                      )
                    
                    )}
                  </Form.Select>

                  <Form.Select defaultValue={toEditProduct.sub_category} style={{marginTop: '2rem'}} onChange={e => setToEditProduct((prev) => ({...prev, sub_category: e.target.value}))} aria-label='Default select example'>
                    <option >Select Sub Category</option>
                    {subCategories.map(el => 
                       (
                        <option  key={el._id} value={el._id}>
                          {el.name}
                        </option>
                      )
                    
                    )}
                  </Form.Select>

                  <div className='col-md-12'>
                    <label> Product name </label>
                    <input
                      type='text'
                      name='eproductName'
                      placeholder='Enter product name'
                      required
                      defaultValue={toEditProduct.name}
                      onInput={e => setToEditProduct((prev) => ({...prev, name: e.target.value}))}
                    />
                  </div>

                  <div className='col-md-12'>
                    <label> Product Description</label>
                    <textarea
                      id=''
                      name=''
                      rows='2'
                      defaultValue={toEditProduct.description}
                      cols=''
                      placeholder='type here Abouts Text'
                      onInput={e => setToEditProduct((prev) => ({...prev, description: e.target.value}))}
                    >
                    </textarea>
                  </div>

                  <div className='col-md-12'>
                    <label> Product Image </label>
                    <input onChange={e => setToEditProduct((prev) => ({...prev, image: e.target.files[0]}))} type='file' size='60'></input>
                  </div>

                  <div className='col-md-12'>
                    <button className='submitbtn'> Submit </button>
                  </div>
                </form>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={() => setShowEditModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showEditCateModal} onHide={() => setShowEditCateModal(false)}>
        <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
          <div className='formadmin'>
              <h2>Edit Products Categories </h2>
              <div className='formdiv'>
                <form onSubmit={handleEditCategorySubmission}>
                  <div className='col-md-12'>
                    <label> Category Name </label>
                    <input
                      type='text'
                      name='categoryName'
                      defaultValue={editCategory?.name}
                      placeholder='Enter Category'
                      required
                      onInput={e => setEditCategory((prev) =>(({...prev, name: e.target.value})))}
                    />
                  </div>

                  <div className='col-md-12'>
                    <label> Category Image </label>
                    <input
                      onChange={e => setEditCategory((prev) =>(({...prev, image: e.target.files[0]})))}
                      type='file'
                      size='60'
                    ></input>
                  </div>

                  <div className='col-md-12'>
                    <label> Category Description </label>
                    <textarea
                      name='description'
                      placeholder='Enter Category Description'
                      defaultValue={editCategory?.description}
                      required
                      onInput={e => setEditCategory((prev) =>(({...prev, description: e.target.value})))}
                    ></textarea>
                  </div>


                  <div className='col-md-12'>
                    <button className='submitbtn'> Submit </button>
                  </div>
                </form>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={showEditSubCatModal} onHide={() => setShowEditSubCatModal(false)}>
          <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
            <div className='formadmin'>
              <h2>Add subcategory </h2>
              <div className='formdiv'>
                <form onSubmit={handleEditSubCategorySubmission}>
                  <div className='col-md-12'>
                    <label> Subcategory name </label>
                    <input
                      type='text'
                      name='subCategoryName'
                      placeholder='Enter subcategory name'
                      defaultValue={editSubCategory?.name}
                      required
                      onInput={e => setEditSubCategory((prev) => ({...prev, name: e.target.value}))}
                    />
                  </div>

                  <div className='col-md-12'>
                    <label> Sub category Image </label>
                    <input
                      onChange={e => setEditSubCategory((prev) => ({...prev, image: e.target.files[0]}))}
                      type='file'
                      size='60'
                    ></input>
                  </div>

                  <div className='col-md-12'>
                    <label> Category Description </label>
                    <textarea
                      name='description'
                      placeholder='Enter Category Description'
                      required
                      defaultValue={editSubCategory?.description}
                      onInput={e => setEditSubCategory((prev) => ({...prev, description: e.target.value}))}
                    ></textarea>
                  </div>

                  <Form.Select defaultValue={editSubCategory?.parent_category} onChange={e => setEditSubCategory((prev) => ({...prev, parent_category: e.target.value}))} aria-label='Default select example'>
                    <option>Select parent category Category</option>
                    {categories.map((el, index) => (
                      <option key={index} value={el._id}>
                        {el.name}
                      </option>
                    ))}
                  </Form.Select>

                  <div className='col-md-12'>
                    <button className='submitbtn' type='submit'> Submit </button>
                  </div>
                </form>
              </div>
            </div>
            </Modal.Body>
        </Modal>

        <div className="tables">
          <h4>Products</h4>
      <table className="table  table-striped table-bordered table-hover table-checkable order-column dataTable">
      <thead><tr>
        <th>Thumb</th>
        <th>Name</th>
        <th>Category</th>
        <th>Sub Category</th>       
        <th>Edit</th>
        <th>Delete</th>
       
       
        </tr></thead>
        <tbody>

          {products.map((el) => 
             <tr key={el._id}>
             <td className='prothumbimg'> <img alt='product' src={environment.baseUrl + el.image} /> </td>
             <td><span className="name">{el.name}</span></td>
             <td>{el.category?.name?.split('-').join(' ')}</td>
             <td>{el.sub_category?.name?.split('-').join(' ')}</td>            
             <td><Button className='edite_btn' onClick={() => handleShowEditModal(el)}><img alt='edit' src={editBtn} /></Button></td>
             <td><Button onClick={() => deleteItem('/products/', el._id, products, setProducts, 'Product' )} className='deletebtn'><img alt='delte' src={deleteBtn} /></Button></td>          
           </tr>
          )}
       
 

        </tbody>
      </table>
      <h4>Categories</h4>
      <table className="table  table-striped table-bordered table-hover table-checkable order-column dataTable">
      <thead><tr>
        <th>Thumb</th>
        <th>Name</th>  
        <th>Description</th>  
        <th>Edit</th>
        {/* <th>Delete</th> */}
       
       
        </tr></thead>
        <tbody>

          {categories.map((el) => 
             <tr key={el._id}>
             <td className='prothumbimg'> <img alt='product' src={environment.baseUrl + el.image} /> </td>
             <td><span className="name">{el.name?.split('-').join(' ')}</span></td>
             <td><span className="name">{el.description}</span></td>
             <td><Button className='edite_btn' onClick={() => handleShowCategoryEditModal(el)}><img alt='edit' src={editBtn} /></Button></td>
             {/* <td><Button className='edite_btn' onClick={() => handleShowEditModal(el._id)}><img alt='edit' src={editBtn} /></Button></td>
             <td><Button onClick={() => deleteItem('/products/', el._id, products, setProducts, 'Product' )} className='deletebtn'><img alt='delte' src={deleteBtn} /></Button></td>           */}
           </tr>
          )}
       
 

        </tbody>
      </table>

      <h4>Sub Categories</h4>
      <table className="table  table-striped table-bordered table-hover table-checkable order-column dataTable">
      <thead><tr>
        <th>Thumb</th>
        <th>Name</th> 
        <th>Category</th> 
        <th>Description</th> 
        <th>Edit</th>
        {/* <th>Delete</th> */}
       
       
        </tr></thead>
        <tbody>

          {subCategoryList.map((el) => 
             <tr key={el._id}>
             <td className='prothumbimg'> <img alt='product' src={environment.baseUrl + el.image} /> </td>
             <td><span className="name">{el.name?.split('-').join(' ')}</span></td>
             <td><span className="name">{el?.parent_category?.name?.split('-').join(' ')}</span></td>
             <td><span className="name">{el?.description}</span></td>
             <td><Button className='edite_btn' onClick={() => handleShowSubCatEditModal(el)}><img alt='edit' src={editBtn} /></Button></td>
             {/* <td><Button className='edite_btn' onClick={() => handleShowEditModal(el._id)}><img alt='edit' src={editBtn} /></Button></td>
             <td><Button onClick={() => deleteItem('/products/', el._id, products, setProducts, 'Product' )} className='deletebtn'><img alt='delte' src={deleteBtn} /></Button></td>           */}
           </tr>
          )}
       
 

        </tbody>
      </table>
    </div>

      </div>
    </div>
  );
}

export default ProductsPage;
