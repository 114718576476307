
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter as Router ,Route,Routes} from "react-router-dom"
import  GenPage  from './Pages/GeneralPageHme';
import  BannerPage  from './Pages/BannerPage';
import AboutPage from './Pages/AboutsPage';
import ProPage from './Pages/Productpage';
import Servpage from './Pages/Services';
import BrandsPage from './Pages/Brands';
import Gallerypage from './Pages/Gallerypage';
import Contactpage from './Pages/ContactPage';
import Settingpage from './Pages/SettingsPage';
import Login from './Pages/LogingPage';




function App() {
  return (
    <div className="App">
      {/* <Admin /> */}
      <Router>
        <Routes>
          <Route path ='/' element={<Login/>}/>
          <Route path ='/' element={<Login/>}/>
          <Route Component={BannerPage} path='/banner'/>
          <Route Component={GenPage} path='/general'/>
          <Route Component={GenPage} path='/general'/>
          <Route Component={AboutPage} path='/about'/> 
          <Route Component={ProPage} path='/products'/> 
          <Route Component={Servpage} path='/services'/>
          <Route Component={BrandsPage} path='/brands'/>
          <Route Component={Gallerypage} path='/gallery'/>
          <Route Component={Contactpage} path='/contact'/>  
          <Route Component={Settingpage} path='/settings'/>         
        </Routes>
      </Router>
      
    </div>
  );
}

export default App;
