import React from 'react';
import Sidebar from '../Components/Sidebar';
import BannerSlider from '../Components/BannerSlider';
import PageHead from '../Components/PageHead';

const BannerPage = () => {
  return (
    <div>
      <PageHead/>
      <div style={{ display: 'flex' }}>
        <Sidebar />
        <BannerSlider />
      </div>
    </div>
  );
};

export default BannerPage;
