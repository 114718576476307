import React from 'react';
import Sidebar from '../Components/Sidebar';
 
import PageHead from '../Components/PageHead';
import Galleripage from '../Components/Gallery';

const Gallerypage = () => {
  return (
    <div>
      <PageHead/>
      <div style={{ display: 'flex' }}>
        <Sidebar />
        <Galleripage/>
      </div>
    </div>
  );
};

export default Gallerypage;
