import {useEffect} from 'react';
import {axiosClient} from '../config/axios.config';
import {getThenBlock} from './getThenBlock';
import {catchBlock} from './catchBlock';

export function fetchItem(url, setFn) {
  return (() =>
    useEffect(() => {
      axiosClient.get(url).then(getThenBlock(setFn)).catch(catchBlock);
    }, []))();
}
