import React from 'react';
import Sidebar from '../Components/Sidebar';
 
import PageHead from '../Components/PageHead';
import Generalpage from '../Components/Generalpage';

const GenPage = () => {
  return (
    <div>
      <PageHead/>
      <div style={{ display: 'flex' }}>
        <Sidebar />
        <Generalpage/>
      </div>
    </div>
  );
};

export default GenPage;
