import React from 'react';
import Sidebar from '../Components/Sidebar';
 
import PageHead from '../Components/PageHead';
import Aboutpage from '../Components/Aboutpage';

const AboutPage = () => {
  return (
    <div>
      <PageHead/>
      <div style={{ display: 'flex' }}>
        <Sidebar />
        <Aboutpage/>
      </div>
    </div>
  );
};

export default AboutPage;
