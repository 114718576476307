import React, { useState } from 'react'
// import { PageHead } from './PageHead'
import { axiosClient } from '../config/axios.config';
import { useNavigate } from 'react-router-dom'

const Loginpage = () => {
  const [email, setEmail] = useState('');
  const [password , setPassword] = useState('');
  const navigate = useNavigate()
 async function onsubmit(e) {
    e.preventDefault();
    await axiosClient.post('/auth/login', { email, password }).then((response) => {
      localStorage.setItem('authToken', response.data.data.token)
      navigate('/banner')
    }).catch(() =>{})
  }
  
  return (  
    <div className='logindiv'> 
  
    <div className='formadmin'>
        <h2>Admin Login </h2>
        <div className='formdiv'>
            <form onSubmit={onsubmit}>
            <div className='col-md-12'>
                    <label> Email  </label>
                    <input type='email' onInput={(e) => setEmail(e.target.value)} name="email" placeholder='Admin' required />
                </div> 

                <div className='col-md-12'>
                    <label> Password </label>
                    <input type='password' onInput={e => setPassword(e.target.value)} name="password" placeholder='***********' required />
                </div>
                
                <div className='col-md-12'>
                   <button className='submitbtn'> Login </button>
                </div>

            </form>
        </div>
    </div>
    </div>

  )
}
export default Loginpage; 
