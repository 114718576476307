import React from 'react'

const PageHead = () => {
  return (
   
    <div className='pagehead'>
        <h2>Welcome to <span> Hydro Power Oil-Field </span> Dashboard</h2>
    </div>        

  )
}
export default PageHead;