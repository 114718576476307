import React from 'react';
import Sidebar from '../Components/Sidebar';
 
import PageHead from '../Components/PageHead';
import Contactspage from '../Components/Contacts';

const Contactpage = () => {
  return (
    <div>
      <PageHead/>
      <div style={{ display: 'flex' }}>
        <Sidebar />
        <Contactspage/>
      </div>
    </div>
  );
};

export default Contactpage;
