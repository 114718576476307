import React, { useState } from 'react';
import { axiosClient } from '../config/axios.config';
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/esm/Button';
import { fetchItem } from '../utils/fetchItem';
import { environment } from '../config/environment';
import { deleteItem } from '../utils/deleteItem';
import deletebtn from '../delete.png'
 


 const BannerSlider = () => {
    const [caption, setCaption] = useState('');
    const [file, selectFile] = useState(null);
    const [banners, setBanners] = useState([]);

    fetchItem('/banner', setBanners);

    async function onSubmit(e) {
        e.preventDefault();
        const formdata = new FormData();
        formdata.append('banner_image', file);
        formdata.append('caption', caption);
        await axiosClient.post('/banner', formdata).then(() => toast.success('Banner added successfully')).catch(() => {})
    }
     
  return (
    <div className='mainright'> 
   
    <div className='formadmin'>
        <h2>Banner Slider </h2>
        <div className='formdiv'>
            <form onSubmit={onSubmit}>
                <div className='col-md-12'>
                    <label> Slider 01 </label>
                    <input type='text' onInput={e => setCaption(e.target.value)} name="caption" placeholder='Slider Caption eg: HYDRAULIC HOSES &   ACCESSORIES' required />                    
                    <input type='file' onChange={e => selectFile(e.target.files[0])} size="60" ></input>
                </div>

               

                


                <div className='col-md-12'>
                   <button className='submitbtn'> Save </button>
                </div>

            </form>

            <div className='editegallery'>
              <div className='row'>
              {banners.map((el) => 
                    <div key={el._id} className='col-md-2'>
                    <div className='gallerythumb'><img alt='alt' src={environment.baseUrl + el.image} /></div>
                    <div className="caa">{el.caption}</div>
                    <Button onClick={() => deleteItem('/banner/' , el._id, banners, setBanners, 'Banner')} className='deletebtn'><img alt='delete' src={deletebtn} /></Button>
                  </div>
              )}
        
              </div>

        </div>
    </div>

    </div>

    </div>
  )
}
export default BannerSlider