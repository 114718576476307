import React from 'react';
import Sidebar from '../Components/Sidebar';
 
import PageHead from '../Components/PageHead';
import Brandpage from '../Components/Brand';

const BrandsPage = () => {
  return (
    <div>
      <PageHead/>
      <div style={{ display: 'flex' }}>
        <Sidebar />
        <Brandpage/>
      </div>
    </div>
  );
};

export default BrandsPage;
