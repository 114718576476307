import React, {useEffect, useState} from 'react';
import {PageHead} from './PageHead';
import {axiosClient} from '../config/axios.config';
import {toast} from 'react-toastify';
import Button from 'react-bootstrap/Button'
import ThumbImg from '../thump.jpeg';
import deletebtn from '../delete.png';

import { environment } from '../config/environment';
import { deleteItem } from '../utils/deleteItem';
import { fetchItem } from '../utils/fetchItem';

const Galleripage = () => {
  const [file, selectFile] = useState(null);
  const [galleries, setGalleries] = useState([]);

   fetchItem('/gallery', setGalleries);

  async function onSubmit(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append('gallery_image', file);
    await axiosClient
      .post('/gallery', formData)
      .then((response) =>{
        toast.success('Gallery added successfully');
        setGalleries([...galleries, response.data.data])
      })
      .catch(() => {});
  }

  return (
    <div className='mainright'>
      <div className='formadmin'>
        <h2>Gallery</h2>
        <div className='formdiv'>
          <form onSubmit={onSubmit}>
            <div className='col-md-12'>
              <label> Add Gallery </label>
              <input
                onChange={e => selectFile(e.target.files[0])}
                type='file'
                size='60'
              ></input>
            </div>

    
            <div className='col-md-12'>
              <button className='submitbtn'> Save </button>
            </div>
          </form>

                 
          <div className='editegallery'>
              <div className='row'>
             {galleries.map((el) => (
             <div key={el._id} className='col-md-3'>
                <div className='gallerythumb'><img alt={el.image} src={environment.baseUrl + el.image} /></div>
               <Button onClick={() => deleteItem('/gallery/',el._id, galleries, setGalleries, 'Gallery')} className='deletebtn'><img alt='dlt' src={deletebtn} /></Button>
            </div>
             ))}
     

              </div>

            </div>

        </div>
      </div>
    </div>
  );
};

export default Galleripage;
