import React, {useEffect, useState} from 'react';
import {PageHead} from './PageHead';
import {axiosClient} from '../config/axios.config';
import {toast} from 'react-toastify';
import { fetchItem } from '../utils/fetchItem';

const Contactspage = () => {
  const [address, setAddress] = useState('');
  const [location_map_url, setLocationMap] = useState('');
  const [locations, setLocations] = useState({});
  fetchItem('/admin/general/location', setLocations);
  async function onSubmit(e) {
    e.preventDefault();
    await axiosClient
      .post('/admin/general/location', {address, location_map_url})
      .then(() => toast.success('Address added successfully'))
      .catch(() => {});
  }

  async function getContactDetails() {
    await axiosClient.get('/admin/general/location')
      .then((res) => {
        const data = res?.data?.data[0];
        setAddress(data?.address);
        setLocationMap(data?.location_map_url);
        setLocations({address: data?.address, location_map_url: data?.location_map_url});
      })
      .catch(() => toast.error('Something went wrong'))
  }

  useEffect(() => {
    getContactDetails();
  },[])
  return (
    <div className='mainright'>
      <div className='formadmin'>
        <h2>Contact</h2>
        <div className='formdiv'>
          <form onSubmit={onSubmit}>
            <div className='col-md-12'>
              <label> Address </label>
              <input
              onInput={e => setAddress(e.target.value)}
                type='text'
                name='username'
                placeholder='Address'
                required
                defaultValue={locations.address}
              />
            </div>

            <div className='col-md-12'>
              <label> Location Map </label>
              <input
               onInput={e => setLocationMap(e.target.value)}
                type='text'
                name='username'
                placeholder='Location URL'
                required
                defaultValue={locations.location_map_url}
              />
            </div>

            <div className='col-md-12'>
              <button className='submitbtn'> Save </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default Contactspage;
