import React, {useState} from 'react';
import {PageHead} from './PageHead';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {Link} from 'react-router-dom';
import {axiosClient} from '../config/axios.config';
import {toast} from 'react-toastify';

import deletebtn from '../delete.png';
import editbtn from '../edit.png';
import { fetchItem } from '../utils/fetchItem';
import { environment } from '../config/environment';
import { deleteItem } from '../utils/deleteItem';






function Servicepage() {
    const [show, setShow] = useState(false);
    // const [editModalShow, setEditModalShow] = useState(false);
    const [services, setServices] = useState([]);

    fetchItem('/services', setServices);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState(null);
  
    async function onSubmit(e) {
      e.preventDefault();
      const formData = new FormData();
      formData.append('service_image', image);
      formData.append('title', title);
      formData.append('description', description);
      await axiosClient
        .post('/services', formData)
        .then((response) => {
          toast.success('Service addes successfully')
          setServices([...services, response.data.data])
        })
        .catch(() => {});
    }

    return (
        <div className='mainright'>
            <div className='productsdiv'>

                <Button variant="primary" onClick={handleShow}> Add Services     </Button>

            <div className='servicesthumb'>
                {services.map((el) => 
                        <div key={el._id} className='servicesthumb_item'>
                        <div className='row '>
                            <div className='col-md-3'>
                                <div className='servicethumb'><img alt='images' src={environment.baseUrl + el.image} /></div>
                            </div>
                            <div className='col-md-7 '>
                                <h2> {el.title}</h2>
                                <p>{el.description}
                                </p>
                            </div>
                            <div className='col-md-2 '>
                                <Button onClick={() => deleteItem('/services/', el._id, services, setServices, 'Service')} className='deletebtn'><img alt='delete' src={deletebtn} /></Button>
                                {/* <Button className='edite_btn' onClick={handleShow}><img src={editbtn} /></Button> */}
                            </div>
                        </div>
                    </div>
                )}
        
                </div>



                <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className='formadmin'>
              <h2>Add Services </h2>
              <div className='formdiv'>
                <form onSubmit={onSubmit}>
                  <div className='col-md-12'>
                    <label> Services Heading </label>
                    <input
                      type='text'
                      name='username'
                      placeholder='Enter Service heading'
                      required
                      onInput={e => setTitle(e.target.value)}
                    />
                  </div>

                  <div className='col-md-12'>
                    <label> Services Description</label>
                    <textarea
                      id=''
                      name=''
                      rows='2'
                      cols=''
                      placeholder='type here Abouts Text'
                      onInput={e => setDescription(e.target.value)}
                    >
                      {' '}
                    </textarea>
                  </div>

                  <div className='col-md-12'>
                    <label> Services Image </label>
                    <input onChange={e => setImage(e.target.files[0])} type='file' size='60'></input>
                  </div>

                  <div className='col-md-12'>
                    <button className='submitbtn'> Submit </button>
                  </div>
                </form>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

                {/* Edite */}
{/* 
                <Modal show={editModalShow} onHide={() => setEditModalShow(false)}>
                    <Modal.Header closeButton>
                      <h2>Edit Service</h2>

                    </Modal.Header>
                    <Modal.Body>

                        <div className='formadmin'>                           
                            <div className='formdiv'>
                                <form>
                                    <div className='col-md-12'>
                                        <label> Services Heading </label>
                                        <input type='text' name="username" placeholder='Enter Category' required />
                                    </div>

                                    <div className='col-md-12'>
                                        <label> Services Description</label>
                                        <textarea id="" name="" rows="2" cols="" placeholder='type here Abouts Text'> </textarea>
                                    </div>

                                    <div className='col-md-12'>
                                        <label> Services Image </label>
                                        <input type='file' size="60" ></input>
                                    </div>

                                    <div className='col-md-12'>
                                        <button className='submitbtn'> Submit </button>
                                    </div>

                                </form>
                            </div>
                        </div>

                    </Modal.Body>                    
                </Modal> */}

                {/*  */}




            </div>

        </div>

    );
}



export default Servicepage;






