import React from 'react';
import Sidebar from '../Components/Sidebar'; 
import PageHead from '../Components/PageHead';
import ProductsPage from '../Components/ProductPages';

const ProPage = () => {
  return (
    <div>
      <PageHead/>
      <div style={{ display: 'flex' }}>
        <Sidebar />
        <ProductsPage/>
      </div>
    </div>
  );
};

export default ProPage;
