import React from 'react';
import Sidebar from '../Components/Sidebar';
 
import PageHead from '../Components/PageHead';
import Loginpage from '../Components/Login';

const Login = () => {
  return (
    <div>
      <PageHead/>
      <div style={{ display: 'flex' }}>
     
        <Loginpage/>
      </div>
    </div>
  );
};

export default Login;
