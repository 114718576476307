import React, {useState} from 'react';
import {PageHead} from './PageHead';
import {toast} from 'react-toastify';
import {axiosClient} from '../config/axios.config';

const Settingspage = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  async function onSubmit(e) {
    e.preventDefault();
    if (password !== confirmPassword) toast.error('Passwords do not match');
    else
      await axiosClient
        .put('/admin/change-password', { current_password: oldPassword, new_password: password})
        .then(() => toast.success('New password  updated successfully'))
        .catch(() => {});
  }

  return (
    <div className='mainright'>
      <div className='formadmin'>
        <h2>Settings </h2>
        <div className='formdiv'>
          <form onSubmit={onSubmit}>
            <div className='col-md-12'>
              <label> Old password </label>
              <input
                type='password'
                onInput={e => setOldPassword(e.target.value)}
                name='email'
                placeholder='Admin'
                required
              />
            </div>

            <div className='col-md-12'>
              <label> new passsword </label>
              <input
                type='password'
                onInput={e => setPassword(e.target.value)}
                name='password'
                placeholder='***********'
                required
              />
            </div>

            <div className='col-md-12'>
              <label> confirm password </label>
              <input
                type='password'
                onInput={e => setConfirmPassword(e.target.value)}
                name='confirm-password'
                placeholder='***********'
                required
              />
            </div>

            <div className='col-md-12'>
              <button className='submitbtn'> Save </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default Settingspage;
