import React, {useEffect, useState} from 'react';
import {PageHead} from './PageHead';
import {axiosClient} from '../config/axios.config';
import {toast} from 'react-toastify';
import { fetchItem } from '../utils/fetchItem';

const Aboutpage = () => {
  const [about_heading, setHeading] = useState('');
  const [home_page_about, setHomePageAbout] = useState('');
  const [inner_page_about, setInnerPageAbout] = useState('');
  const [about, setAbout] = useState({});
  fetchItem('/admin/general/about', setAbout);
  async function onSubmit(e) {
    e.preventDefault();
    await axiosClient
      .post('/admin/general/about', {
        about_heading,
        home_page_about,
        inner_page_about,
      })
      .then(() => toast.success('About added successfully'))
      .catch(() => {toast.error('Something went wrong')});
  };
  async function getAboutDetails(){
    await axiosClient.get('/admin/general/about')
    .then((res) => {
        const data = res?.data?.data[0];
        setHeading(data?.about_heading);
        setInnerPageAbout(data?.inner_page_about);
        setHomePageAbout(data?.home_page_about);
        setAbout({home_page_about: data?.home_page_about, inner_page_about: data?.inner_page_about, about_heading: data?.about_heading})
    })
    .catch(() => toast.error('Something went wrong'))
  }

  useEffect(() => {
    getAboutDetails()
  },[])
  return (
    <div className='mainright'>
      <div className='formadmin'>
        <h2>About The Company </h2>
        <div className='formdiv'>
          <form onSubmit={onSubmit}>
            <div className='col-md-12'>
              <label> Heading </label>
              <input
               onInput={e => setHeading(e.target.value)}
                type='text'
                name='username'
                placeholder='Enter the Heading'
                required
                defaultValue={about?.about_heading}
              />
            </div>

            <div className='col-md-12'>
              <label> Home page About Text</label>
              <textarea
               onInput={e => setHomePageAbout(e.target.value)}
                id=''
                name=''
                rows='4'
                cols=''
                placeholder='type here Abouts Text'
                defaultValue={about?.home_page_about}
              >
              </textarea>
            </div>

            <div className='col-md-12'>
              <label>Inner Page Abouts Text</label>
              <textarea
               onInput={e => setInnerPageAbout(e.target.value)}
                id=''
                name=''
                rows='4'
                cols=''
                placeholder='type here Abouts Text'
                defaultValue={about?.inner_page_about}
              >
              </textarea>
            </div>

            <div className='col-md-12'>
              <button className='submitbtn' type='submit'> Save </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Aboutpage;
