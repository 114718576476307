import React, { useState, useEffect } from 'react';
import './sidebar.css';
import Logohydro from './logo.svg';
import { Link, useLocation , useNavigate} from "react-router-dom";

const Sidebar = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const [activeItem, setActiveItem] = useState('');

  useEffect(() => {
    // Extract the pathname from the location object
    const pathname = location.pathname;

    // Extract the item name from the pathname (assuming the pathname starts with '/')
    const itemName = pathname.substring(1);

    // Set the active item based on the current pathname
    setActiveItem(itemName);
  }, [location]);

  function logout() {
    localStorage.removeItem('authToken');
    navigation('/')

  }

  return (
    <div className='sidemenu'>
      <div className='logo'>
        <img src={Logohydro} alt="Logo" />
      </div>
      <ul className='sideitem'>
        <li className={activeItem === 'general' ? 'active' : ''}>
          <Link to="/general">
            General Contacts
          </Link>
        </li>
        <li className={activeItem === 'about' ? 'active' : ''}>
          <Link to="/about">
            About
          </Link>
        </li>
        <li className={activeItem === 'products' ? 'active' : ''}>
          <Link to="/products">
            Products
          </Link>
        </li>
        <li className={activeItem === 'services' ? 'active' : ''}>
          <Link to="/services">
            Services
          </Link>
        </li>
        <li className={activeItem === 'brands' ? 'active' : ''}>
          <Link to="/brands">
            Brands
          </Link>
        </li>
        <li className={activeItem === 'banner' ? 'active' : ''}>
          <Link to="/banner">
            Bannner
          </Link>
        </li>
        <li className={activeItem === 'gallery' ? 'active' : ''}>
          <Link to="/gallery">
            Gallery
          </Link>
        </li>
        <li className={activeItem === 'contact' ? 'active' : ''}>
          <Link to="/contact">
            Contact
          </Link>
        </li>
        <li className={activeItem === 'settings' ? 'active' : ''}>
          <Link to="/settings">
            Settings
          </Link>
        </li>
      </ul>
      <div className='col-md-12'>
        <button onClick={logout} className='logoutbtn'> Logout </button>
      </div>
    </div>
  );
};

export default Sidebar;
