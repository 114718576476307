import React, {useState} from 'react';
import {PageHead} from './PageHead';
import {axiosClient} from '../config/axios.config';
import {toast} from 'react-toastify';
import { fetchItem } from '../utils/fetchItem';
import { environment } from '../config/environment';
import deletebtn from '../delete.png';
import { deleteItem } from '../utils/deleteItem';
import Button from 'react-bootstrap/esm/Button';

const Brandpage = () => {
  const [file, selectFile] = useState('');
  const [brands, setBrands] = useState([]);

  fetchItem('/brand', setBrands);

  async function onsubmit(e) {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append('brand_image', file);
    await axiosClient
      .post('/brand', formdata)
      .then((response) =>{
        toast.success('Brand added successfully')
        setBrands([...brands, response.data.data])
      } )
      .catch(() => {});
  }

  return (
    <div className='mainright'>
      <div className='formadmin'>
        <h2>Brands</h2>
        <div className='formdiv'>
          <form onSubmit={onsubmit}>
            <div className='col-md-12'>
              <label> Add Brands </label>
              <input onChange={e => selectFile(e.target.files[0])} type='file' size='60'></input>
            </div>

            <div className='col-md-12'>
              <button className='submitbtn'> Save </button>
            </div>
          </form>

          
          <div className='editegallery'>
              <div className='row'>
              {brands.map((el) => 
                    <div key={el._id} className='col-md-2'>
                    <div className='gallerythumb'><img alt='alt' src={environment.baseUrl + el.image} /></div>
                    <Button onClick={() => deleteItem('/brand/' , el._id, brands, setBrands, 'Brand')} className='deletebtn'><img alt='delete' src={deletebtn} /></Button>
                  </div>
              )}
        
              </div>

            </div>
        </div>
      </div>
    </div>
  );
};

export default Brandpage;
