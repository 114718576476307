import React from 'react';
import Sidebar from '../Components/Sidebar';
 
import PageHead from '../Components/PageHead';
import Servicepage from '../Components/Service';

const Servpage = () => {
  return (
    <div>
      <PageHead/>
      <div style={{ display: 'flex' }}>
        <Sidebar />
        <Servicepage/>
      </div>
    </div>
  );
};

export default Servpage;
