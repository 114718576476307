import React, {useEffect, useState} from 'react';
import {PageHead} from './PageHead';
import {axiosClient} from '../config/axios.config';
import {toast} from 'react-toastify';
import { fetchItem } from '../utils/fetchItem';

const Generalpage = () => {
  const [manager_number, setManagerNumber] = useState('');
  const [workshop_number, setWorkshopNumber] = useState('');
  const [sales_number, setSalesNumber] = useState('');
  const [landline, setLandline] = useState('');
  const [email_one, setEmailOne] = useState('');
  const [email_two, setEmailTwo] = useState('');

  const [details, setDetails] = useState({});

  fetchItem('/admin/general/details', setDetails);


  async function onsubmit(e) {
    e.preventDefault();
    await axiosClient
      .post('/admin/general/details', {
        manager_number,
        workshop_number,
        sales_number,
        landline,
        email_one,
        email_two,
      })
      .then(() => toast.success('General details added successfully'))
      .catch(() => {toast.error('Something went wrong')});
  }

  async function getGeneralDetails (){
    await axiosClient.get('/admin/general/details')
      .then((res) => {
        const data = res?.data?.data[0];
        setDetails({
          manager_number: data?.manager_number,
          workshop_number: data?.workshop_number,
          sales_number: data?.sales_number,
          landline: data?.landline,
          email_one: data?.email_one,
          email_two: data?.email_two,
        })
        setManagerNumber(data?.manager_number)
        setWorkshopNumber(data?.workshop_number);
        setSalesNumber(data?.sales_number);
        setLandline(data?.landline);
        setEmailOne(data?.email_one);
        setEmailTwo(data?.email_two);
      })
      .catch(() => toast.error('Something went wrong'))
  }

  useEffect(() => {
    getGeneralDetails();
  },[])
  return (
    <div className='mainright'>
      <div className='formadmin'>
        <h2>General Contacts </h2>
        <div className='formdiv'>
          <form onSubmit={onsubmit}>
            <div className='col-md-12'>
              <label> Manager Number </label>
              <input
                onInput={e => setManagerNumber(e.target.value)}
                type='text'
                name='username'
                placeholder='Enter Manager Number'
                required
                defaultValue={details.manager_number}
              />
            </div>

            <div className='col-md-12'>
              <label> Workshop Number </label>
              <input
              onInput={e => setWorkshopNumber(e.target.value)}
                type='text'
                name='username'
                placeholder='Workshop Number'
                required
                defaultValue={details.workshop_number}
              />
            </div>

            <div className='col-md-12'>
              <label> Sales Number </label>
              <input
              onInput={e => setSalesNumber(e.target.value)}
                type='text'
                name='username'
                placeholder='Sales Number'
                required
                defaultValue={details.sales_number}
              />
            </div>

            <div className='col-md-12'>
              <label> Land line Number </label>
              <input
              onInput={e => setLandline(e.target.value)}
                type='text'
                name='username'
                placeholder='Land line Number'
                required
                defaultValue={details.landline}
              />
            </div>

            <div className='col-md-12'>
              <label> Email -1 </label>
              <input
              onInput={e => setEmailOne(e.target.value)}
                type='email'
                name='username'
                placeholder='Enter Email  '
                required
                defaultValue={details.email_one}
              />
            </div>

            <div className='col-md-12'>
              <label> Email -2 </label>
              <input
              onInput={e => setEmailTwo(e.target.value)}
                type='email'
                name='username'
                placeholder='Enter Email -2  '
                required
                defaultValue={details.email_two}
              />
            </div>

            <div className='col-md-12'>
              <button className='submitbtn'> Save </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Generalpage;
